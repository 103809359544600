.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 15px;
}

.datesContainer {
  display: flex;
  gap: 4px;
  padding: 4px;
  flex-wrap: wrap;
}

.dateCard {
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(37, 63, 131);
  border-radius: 5px;
  color: #f2f2f2;
}

.madeAvailable {
  background-color: rgb(16, 159, 0);
  border: 1px solid rgb(6, 97, 0);
}

.unavailable {
  background-color: rgb(173, 0, 0);
  color: rgb(255, 255, 255);
  box-shadow: none;
  border: 1px solid rgb(184, 0, 0);
}

.dateTitle {
  transform: translateY(10px);
  font-size: 16px;
  border-bottom: 1px solid black;
}
.btn {
  position: relative;
  justify-content: center;
  margin: 0 auto;
  height: 42px;
  background-color: rgb(253, 199, 11);
  display: flex;
  border: none;
  padding: 10px;
  width: 200px;
  cursor: pointer;
}

.btn:disabled {
  background-color: rgb(146, 146, 146);
  color: #f2f2f2;
  cursor: not-allowed;
}

@media only screen and (max-width: 699px) {
  /* For mobile phones: */
  .container {
    align-items: flex-start;
  }
  .btn {
    margin: 0;
  }
}
