.main-background {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main-background > img {
  filter: brightness(70%);
  object-fit: cover;
  max-width: 100%;
  height: calc(100vh - 80px) !important;
}

.book-now-btn-wrapper {
  transform: translateX(-50%);
  position: absolute;
  top: calc(50% - 27px);
  left: 50%;
}

.right-btn {
  transform: translateY(-50%);
  position: fixed;
  top: 50%;
  right: 0;
}

.left-btn {
  transform: translateY(-50%);
  position: fixed;
  top: 50%;
}

.right-btn:hover {
  filter: brightness(80%);
}

.left-btn:hover {
  filter: brightness(80%);
}

.book-now-btn {
  color: rgba(0, 0, 0, 0.94) !important;
}

.book-now-btn:hover {
  background-color: rgba(253, 201, 11, 0.94) !important;
}

.social-stack {
  margin: 0px 45px 45px 0px;
  padding: 0;
  position: absolute;
  bottom: 0;
  right: 0;
}

.social-stack img:hover {
  cursor: pointer;
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
  filter: brightness(80%);
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

@media only screen and (max-width: 899px) {
  /* For mobile phones: */
  .book-now-btn {
    max-width: 120px;
    max-height: 47.5px;
    font-size: 12px !important;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (max-height: 389px) {
  .main-background > img {
    max-height: 400vh;
  }
}

.gallery-wrapper {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 96px) !important;
}

.gallery-wrapper img {
  filter: brightness(70%);
  object-fit: cover !important;
  max-width: 100%;
  height: calc(100vh - 80px) !important;
}
