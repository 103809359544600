.container {
  padding: 0 10px;
  background-color: rgb(229, 232, 232);
  display: flex;
  height: calc(100vh - 80px);
  flex-direction: column;
}

.filterContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  margin: 10px 0;
  gap: 10px;
}

.calendarContainer,
.searchBtnContainer,
.mobileContainer {
  display: flex;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  justify-content: end;
  align-items: center;
  gap: 10px;
}

.calendar,
.inputField {
  width: 200px;
}

.text {
  margin: 0 0 5px 0;
  min-width: max-content;
}

.inputField {
  height: 40px;
  border-radius: 4px;
  border: 1px solid rgb(175, 173, 173);
}

.btn {
  width: 150px;
  height: 50px;
  border: none;
  padding: 7px;
  letter-spacing: 1.1px;
  border-radius: 4px;
  color: rgb(25, 25, 41);
}

.download {
  margin: 0;
  padding: 0;
  text-align: center;
  align-items: center;
}

.editButton {
  margin: 0;
  padding: 0;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
}

@media only screen and (max-width: 899px) {
  /* For mobile phones: */
  .filterContainer {
    display: grid;
  }
  .btn {
    width: 100px;
  }
  .calendarContainer,
  .mobileContainer {
    display: flex;
    justify-content: space-between;
  }
}

td {
  text-align: justify;
}
