.mainWrapper {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
}

.background {
  flex: 1;
  width: 100%;
  height: 40vh;
}

.whitePaper {
  display: flex;
  justify-content: center;
  height: 40vh;
}

.footer {
  width: 100%;
  height: 8px;
  background-color: #01245a;
}

.socialMedia {
  display: flex;
  gap: 12px;
}

.facebookIcon {
  background: url("../../assets/social/facebook.svg") no-repeat center center;
  background-size: 100% 100%;
  height: 24px;
  width: 24px;
}

.instaIcon {
  background: url("../../assets/social/instagram.svg") no-repeat center center;
  background-size: 100% 100%;
  height: 24px;
  width: 24px;
}
