.container {
  width: max-content;
}

.title {
  color: rgb(234, 234, 234);
  font-size: 15px;
  letter-spacing: 1.2px;
  padding: 0 6px;
  margin-bottom: 7px;
}

.row {
  display: flex;
  margin-top: 3px;
  padding: 4px 6px;
  cursor: pointer;
}

.row:hover {
  background-color: rgb(141, 141, 141);
}

.nameInitial {
  display: flex;
  align-items: center;
  color: hsl(226deg 69% 63% / 100%);
  border-radius: 4px;
  background: hsl(0deg 0% 100% / 100%);
  font-size: 11px;
  font-weight: bold;
  justify-content: center;
  width: 16px;
  height: 16px;
  text-transform: uppercase;
  padding: 8px;
}

.text {
  margin-left: 15px;
  font-size: 13px;
  letter-spacing: 1.1px;
  word-break: break-all;
  color: rgb(231, 225, 225);
}

.signoutIcon {
  background: url("../../../assets/icons/arrow-in-blue-background.svg")
    no-repeat center center;
  background-size: 100% 100%;
  height: 16px;
  width: 16px;
}

.pageIcon {
  background: url("../../../assets/icons/blank-page.svg") no-repeat center
    center;
  background-size: 100% 100%;
  height: 16px;
  width: 16px;
}

.arrowIcon {
  background: url("../../../assets/icons/right-arrow-in-circle.svg") no-repeat
    center center;
  background-size: 100% 100%;
  height: 16px;
  width: 16px;
}

.settingsIcon {
  background: url("../../../assets/icons/right-arrow-in-circle.svg") no-repeat center center;
  background-size: 100% 100%;
  height: 16px;
  width: 16px;
}
