.container {
  margin: 50px 50px 30px 50px;
  box-shadow: -2px 2px 12px rgb(75, 75, 75);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
}

.calendarContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
}

.typeContainer {
  position: relative;
  display: flex;
  flex: 1;
  margin: 10px 0 20px 0;
}

.heading3 {
  font-size: 16px;
  width: 100%;
  background-color: rgb(21, 40, 89);
  padding: 4px 10px;
  letter-spacing: 1px;
  color: rgb(232, 235, 240);
}

.available {
  background-color: rgb(255, 237, 176);
  border: 1px solid rgb(255, 219, 103);
}

.typeCard {
  box-shadow: 0 1px 3px rgb(75, 75, 75);
  border-radius: 8px;
  padding: 5px 10px;
  margin: 0 6px;
  border: none;
  background-color: rgb(255, 237, 176);
  border: 1px solid rgb(255, 219, 103);
  cursor: pointer;
}

.sportContainer {
  margin-bottom: 20px;
}

.sportsCards {
  display: flex;
}

.sportCard {
  box-shadow: 0 1px 3px rgb(75, 75, 75);
  border-radius: 8px;
  padding: 10px 10px;
  margin: 0 6px;
  background-color: rgb(255, 237, 176);
  border: 1px solid rgb(255, 219, 103);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.cricketSportCard {
  background: url("../../assets/icons/cricket-sport-icon-black.svg") no-repeat
    center center;
  background-size: 100% 100%;
  height: 34px;
  width: 34px;
}

.pickeBallSportCard {
  composes: cricketSportCard;
  background-image: url("../../assets/icons/pickleball-black.svg");
}

.footballSportCard {
  composes: cricketSportCard;
  background-image: url("../../assets/icons/football-black.svg");
}

.selectedSport .cricketSportCard {
  background-image: url("../../assets/icons/cricket-sport-icon-white.svg");
}

.selectedSport .footballSportCard {
  background-image: url("../../assets/icons/football-white.svg");
}

.selectedSport .pickeBallSportCard {
  background-image: url("../../assets/icons/pickleball-white.svg");
}
.selectedType,
.selectedSport {
  background-color: rgb(37, 63, 131);
  border: 1px solid rgb(21, 40, 89);
  box-shadow: 4px 4px 6px rgb(65, 81, 122);
  color: #ffffff;
}

.btn {
  position: relative;
  justify-content: center;
  margin: 0 auto;
  height: 42px;
  background-color: rgb(253, 199, 11);
  display: flex;
  border: none;
  padding: 10px;
  width: 200px;
  flex: 1;
  cursor: pointer;
}

.btn:disabled {
  background-color: rgb(146, 146, 146);
  color: #f2f2f2;
  cursor: not-allowed;
}

.btnContainer {
  margin-bottom: 20px;
}
@media only screen and (max-width: 899px) {
  /* For mobile phones: */
  .container {
    flex-direction: column;
  }

  .calendarContainer {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 699px) {
  /* For mobile phones: */
  .container {
    margin: 5px;
    box-shadow: none;
    flex-direction: column;
  }

  .sportCard {
    margin: 0 6px 0 0;
  }
}
