.main-wrapper {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
}

.white-paper {
  display: flex;
  justify-content: center;
  height: calc(100% + 100px);
}

.background {
  max-height: 500px;
  min-height: 200px;
  width: 100%;
  /* z-index: -1; */
  overflow: hidden;
}

.background img {
  object-fit: cover;
}

.background > img {
  filter: brightness(70%);
  max-width: 100%;
}

.right-btn {
  margin: 0;
  padding: 0;
  transform: translateY(-50%);
  position: absolute;
  top: 40%;
  right: 0;
}

.left-btn {
  margin: 0;
  padding: 0;
  transform: translateY(-50%);
  position: absolute;
  top: 40%;
}

.right-btn:hover {
  filter: brightness(80%);
}

.left-btn:hover {
  filter: brightness(80%);
}

.footer {
  width: 100%;
  height: 16px;
  background-color: #01245a;
  margin: 0;
  padding: 0;
  position: relative;
  bottom: 0;
}

.loginContainer {
  position: relative;
}

.loginPanel {
  width: 100px;
  height: 100px;
  background-color: aliceblue;
  position: absolute;
  top: 20;
  right: 0;
  z-index: 200;
}

.links {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  gap: 5px;
  padding: 10px;
  align-items: center;
}

.links div {
  color: rgb(253, 199, 11);
  font-size: 10px;
  cursor: pointer;
}

.links span {
  color: rgb(253, 199, 11);
  font-size: 15px;
}

.privacy-policy-container p,
.terms-and-conditions-container p,
.refund-policy-container p {
  display: block;
  margin: 10px 0;
}

.privacy-policy-container,
.refund-policy-container,
.terms-and-conditions-container {
  padding: 10px;
}

.privacy-policy-container h1,
.terms-and-conditions-container h1,
.refund-policy-container h1 {
  display: block;
  font-size: 20px;
  font-weight: 800;
  margin-top: 20px;
}
