.container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  min-height: 500px;
  overflow: scroll;
  background-color: rgb(223, 223, 223);
}

.mainWrapper {
  display: grid;
  grid-template-columns: 300px 1fr;
  height: 100%;
  padding: 20px;
  gap: 20px;
}

.sidebar {
  height: 100%;
  background-color: #ffffff;
}

.profile {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #ffffff;
}

.number {
  font-size: 18px;
  color: #696969;
}

.tabContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.rateTabContainer {
  display: flex;
  flex-direction: column;
}

.tab {
  padding: 10px;
  background-color: #f2f2f2;
  flex: 1;
  cursor: pointer;
}

.activeTab {
  padding: 10px;
  background-color: rgb(223, 223, 223);
  flex: 1;
}

.main {
  background-color: #ffffff;
  padding: 4px;
}

.footer {
  width: 100%;
  height: 8px;
  background-color: #01245a;
}

.heading3 {
  text-align: center;
  font-size: 16px;
  width: 100%;
  background-color: rgb(21, 40, 89);
  padding: 4px 10px;
  letter-spacing: 1px;
  color: rgb(232, 235, 240);
}

.availableSport {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}

.sportCard {
  box-shadow: 0 1px 3px rgb(75, 75, 75);
  border-radius: 8px;
  padding: 5px 10px;
  margin: 0 6px;
  border: none;
  background-color: rgb(255, 237, 176);
  border: 1px solid rgb(255, 219, 103);
  cursor: pointer;
}

.sportCard:disabled {
  background-color: rgb(199, 199, 199);
  color: rgb(255, 255, 255);
  box-shadow: none;
  border: none;
  cursor: not-allowed;
}

.selectedSport {
  background-color: rgb(37, 63, 131);
  border: 1px solid rgb(21, 40, 89);
  box-shadow: 4px 4px 6px rgb(65, 81, 122);
  color: #ffffff;
}
@media only screen and (max-width: 899px) {
  /* For mobile phones: */
  .mainWrapper {
    grid-template-columns: 1fr;
  }
  .availableSport {
    display: flex;
    flex-direction: row;
    padding: 5px 10px 5px 10px;
    gap: 10px;
  }

  .rateTabContainer {
    padding: 5px 5px 0 5px;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
}
