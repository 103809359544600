* {
  margin: 0px;
  font-family: Roboto;
  padding: 0;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

.logo-img {
  margin-left: 4rem;
}

.login-btn:hover {
  background-color: rgba(253, 201, 11, 0.94) !important;
}

@media only screen and (max-width: 899px) {
  /* For mobile phones: */
  .logo-img {
    margin-left: 0rem;
  }
}

.main-container {
  min-height: 100vh;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
}
