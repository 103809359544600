.container {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.text {
  flex: 1;
  display: flex;
  padding: 5px 20px;
  background-color: #f2f2f2;
  font-size: 17px;
}

.textarea {
  margin: 15px;
}

.btn {
  position: relative;
  justify-content: center;
  margin: 0 auto;
  background-color: rgb(253, 199, 11);
  display: flex;
  border: none;
  padding: 10px;
  height: 42px;
  width: 200px;
  cursor: pointer;
}

.btn:disabled {
  background-color: rgb(146, 146, 146);
  color: #f2f2f2;
  cursor: not-allowed;
}
