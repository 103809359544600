.snackBar {
  margin-top: calc(100vh - 80px);
}

.snackBar :global(.ant-message-notice-content) {
  background-color: hsl(0deg 0% 20% / 100%);
  color: hsl(0deg 0% 100% / 100%);
  border-radius: 5px;
}

.snackBarContent {
  display: grid;
  grid-template-columns: 25px auto;
}

.icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  background: transparent no-repeat 0 center;
  margin-right: 5px;
  background-size: 100% 100%;
}

.successIcon {
  composes: icon;
  background-image: url("../../../../assets/icons/check-circle-success.svg");
}

.errorIcon {
  composes: icon;
  background-image: url("../../../../assets/icons/cross-circle-error.svg");
}

@media only screen and (max-width: 899px) {
  /* For mobile phones: */
  .snackBar {
    margin-top: 20px;
  }
}
