.container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  min-height: 500px;
  overflow: scroll;
  background-color: rgb(223, 223, 223);
}

.mainWrapper {
  display: grid;
  grid-template-columns: 300px 1fr;
  height: 100%;
  padding: 20px;
  gap: 20px;
}

.sidebar {
  height: 100%;
  background-color: #ffffff;
}

.profile {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #ffffff;
}

.number {
  font-size: 18px;
  color: #696969;
}

.tabContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tab {
  padding: 10px;
  background-color: #f2f2f2;
  flex: 1;
  cursor: pointer;
}

.activeTab {
  padding: 10px;
  background-color: rgb(223, 223, 223);
  flex: 1;
}

.main {
  background-color: #ffffff;
  padding: 4px;
}

.footer {
  width: 100%;
  height: 8px;
  background-color: #01245a;
}

@media only screen and (max-width: 899px) {
  /* For mobile phones: */
  .mainWrapper {
    grid-template-columns: 1fr;
  }

  .sidebar {
    display: none;
  }
}
