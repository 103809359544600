.tabGroup {
  display: flex;
  gap: 4px;
}

.tab {
  justify-content: center;
  display: flex;
  padding: 10px;
  background-color: #f2f2f2;
  flex: 1;
}

.activeTab {
  justify-content: center;
  background-color: rgb(253, 199, 11);
  display: flex;
  padding: 10px;
  flex: 1;
}

.card {
  padding: 10px;
  box-shadow: 3px 5px 3px #cccccc;
  margin: 10px 15px 0 15px;
  border: 1px solid #cccccc;
  background-color: #f9f9f9;
}

.title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 5px;
  margin-bottom: 15px;
  display: flex;
  align-items: cente;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
}

.title .info {
  font-size: 13px;
}

.info {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  margin-bottom: 5px;
  letter-spacing: 0.7px;
}

.sportInfo {
  composes: info;
  font-size: 16px;
}

@media only screen and (max-width: 899px) {
  /* For mobile phones: */
  .title {
    font-size: 14px;
  }

  .info {
    font-size: 12px;
    flex-direction: column;
  }

  .sportInfo {
    composes: info;
    font-size: 14px;
  }
}
