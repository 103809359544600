.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 400px;
  padding: 10px 13px;
  align-items: center;
  background-color: rgb(237, 240, 242);
}

.text {
  padding: 10px;
  color: hsla(232, 50%, 45%, 1);
  font-size: 15px;
}

.otp,
.mobileInput {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 20px;
}

.btn {
  position: relative;
  width: 100%;
  border: none;
  padding: 7px;
  height: 34px;
  letter-spacing: 1.1px;
  background-color: rgb(253, 199, 11);
  color: #000000;
}

.btn:disabled {
  background-color: rgb(146, 146, 146);
  color: #f2f2f2;
  cursor: not-allowed;
}

.resend {
  align-self: flex-end;
  color: hsla(232, 50%, 45%, 1);
  text-decoration: underline;
}

.close {
  position: absolute;
  top: -18px;
  right: -18px;
  color: rgb(255, 0, 0);
  background-color: white;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.inputField {
  background-color: rgb(255, 255, 255);
  width: 100%;
}

.error {
  color: red;
}

@media only screen and (max-width: 899px) {
  /* For mobile phones: */
  .container {
    width: 100vw;
  }

  .close {
    top: 4px;
    right: 4px;
  }
}
