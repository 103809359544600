.container {
  margin: 50px 50px 30px 50px;
  box-shadow: -2px 2px 12px rgb(75, 75, 75);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
}

.calendarContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
}

.date,
.finalSlot {
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: 700;
  width: 100%;
  padding: 8px;
  border: 1px solid rgb(192, 190, 190);
  border-radius: 5px;
  background-color: rgb(248, 248, 248);
  box-shadow: 2px 2px 12px rgb(161, 161, 161);
}

.turfContainer {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 20px;
  justify-content: space-between;
}

.heading3 {
  font-size: 16px;
  width: 100%;
  background-color: rgb(21, 40, 89);
  padding: 4px 10px;
  letter-spacing: 1px;
  color: rgb(232, 235, 240);
}

.slotContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.slot {
  padding: 7px 8px;
  margin: 4px 0px;
  border-radius: 4px;
  border: none;
  box-shadow: 4px 4px 6px rgb(161, 161, 161);
  width: 50%;
}

.slot:disabled,
.sportCard:disabled {
  background-color: rgb(199, 199, 199);
  color: rgb(255, 255, 255);
  box-shadow: none;
  border: none;
  cursor: not-allowed;
}

.available {
  background-color: rgb(255, 237, 176);
  border: 1px solid rgb(255, 219, 103);
}

.unavailable {
  background-color: rgb(199, 199, 199);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(181, 181, 181);
}

.madeAvailable {
  background-color: rgb(16, 159, 0);
  border: 1px solid rgb(6, 97, 0);
}

.madeUnavailable {
  background-color: rgb(173, 0, 0);
  color: rgb(255, 255, 255);
  box-shadow: none;
  border: 1px solid rgb(184, 0, 0);
}
.notAvailable {
  background-color: rgb(199, 199, 199);
  color: rgb(255, 255, 255);
  box-shadow: none;
}

.durationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-top: 20px;
}

.availableTurf {
  padding: 10px 0 20px 0;
  border-radius: 8px;
  display: flex;
  width: 100%;
}

.turfCard {
  box-shadow: 0 1px 3px rgb(75, 75, 75);
  border-radius: 8px;
  padding: 5px 10px;
  margin: 0 6px;
  border: none;
  background-color: rgb(255, 237, 176);
  border: 1px solid rgb(255, 219, 103);
  cursor: pointer;
}
.sportContainer {
  margin-bottom: 20px;
}

.sportsCards {
  display: flex;
}

.sportCard {
  box-shadow: 0 1px 3px rgb(75, 75, 75);
  border-radius: 8px;
  padding: 10px 10px;
  margin: 0 6px;
  border: none;
  background-color: rgb(255, 237, 176);
  border: 1px solid rgb(255, 219, 103);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.cricketSportCard {
  background: url("../../assets/icons/cricket-sport-icon-black.svg") no-repeat
    center center;
  background-size: 100% 100%;
  height: 34px;
  width: 34px;
}

.pickeBallSportCard {
  composes: cricketSportCard;
  background-image: url("../../assets/icons/pickleball-black.svg");
}

.footballSportCard {
  composes: cricketSportCard;
  background-image: url("../../assets/icons/football-black.svg");
}

.selectedSport .cricketSportCard {
  background-image: url("../../assets/icons/cricket-sport-icon-white.svg");
}

.selectedSport .footballSportCard {
  background-image: url("../../assets/icons/football-white.svg");
}

.selectedSport .pickeBallSportCard {
  background-image: url("../../assets/icons/pickleball-white.svg");
}

.turfCard:disabled {
  background-color: rgb(199, 199, 199);
  color: rgb(255, 255, 255);
  box-shadow: none;
  border: none;
  cursor: not-allowed;
}

.cost {
  padding: 5px 30px;
  font-weight: 500;
  font-size: 20px;
  border-radius: 5px;
  background-color: rgb(245, 202, 62);
  justify-self: flex-end;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  display: none;
  letter-spacing: 1px;
}

.selectedTime,
.selectedTurf,
.selectedSport {
  background-color: rgb(37, 63, 131);
  border: 1px solid rgb(21, 40, 89);
  box-shadow: 4px 4px 6px rgb(65, 81, 122);
  color: #ffffff;
}

.msg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  position: relative;
  justify-content: center;
  margin: 0 auto;
  height: 42px;
  background-color: rgb(253, 199, 11);
  display: flex;
  border: none;
  padding: 10px;
  width: 200px;
  flex: 1;
  cursor: pointer;
}

.btn:disabled {
  background-color: rgb(146, 146, 146);
  color: #f2f2f2;
  cursor: not-allowed;
}

.costMobileScreen {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: rgb(255, 233, 151);
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
}

.costMobileScreen strong {
  font-size: 18px;
  padding: 5px 0 0 0;
}

.btnContainer {
  margin-bottom: 20px;
}
@media only screen and (max-width: 899px) {
  /* For mobile phones: */
  .container {
    flex-direction: column;
  }

  .turfContainer {
    margin-top: 0;
  }

  .calendarContainer {
    margin-bottom: 0;
  }

  .date,
  .finalSlot {
    display: none;
  }

  .cost {
    display: none;
  }

  .costMobileScreen {
    grid-template-columns: 1fr 100px;
  }
}

@media only screen and (max-width: 699px) {
  /* For mobile phones: */
  .container {
    margin: 5px;
    box-shadow: none;
    flex-direction: column;
  }

  .sportCard {
    margin: 0 6px 0 0;
  }
}
