ul,
li {
  list-style-type: none;
}

button:focus {
  outline: 0;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.container {
  margin: 0 auto;
}
