.ant-table-thead .ant-table-cell {
  background-color: hsl(227deg 64% 96% / 100%);
  border-top: 1px solid hsl(226deg 67% 85% / 100%);
  border-bottom: 1px solid hsl(226deg 67% 85% / 100%);
  font-size: 0.93rem;
  color: #6688a4;
}

.ant-table-thead .ant-table-cell::before {
  display: none;
}

.ant-table-tbody .ant-table-cell {
  font-size: 0.93rem;
  color: #3d3c3c;
}

.ant-table-body {
  overflow-y: auto !important;
}

.ant-input:active {
  border-color: rgb(21, 40, 89);
}

.ant-input:focus {
  border-color: rgb(21, 40, 89);

  box-shadow: none;
}
