.modal {
  background-color: hsla(0, 0%, 100%, 1);
  outline: none;
  box-shadow: rem(0 24px 38px) hsla(0, 0%, 0%, 0.14),
    rem(0 9px 46px) hsla(0, 0%, 0%, 0.12), rem(0 11px 15px) hsla(0, 0%, 0%, 0.2);
  border-radius: 4px;
  max-height: 80vh;
  z-index: 100;
}

.overlay {
  position: fixed;
  inset: 0;
  background: hsla(0, 0%, 0%, 0.32);
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}
