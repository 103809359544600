.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 300px;
  background-color: rgb(237, 240, 242);
  min-height: 200px;
}

.close {
  position: absolute;
  top: -18px;
  right: -18px;
  color: rgb(255, 0, 0);
  background-color: white;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.title {
  color: rgb(246, 255, 0);
  font-size: 20px;
  background-color: rgb(21, 40, 89);
  padding: 10px;
}

.content {
  flex: 1;
  color: rgb(255, 0, 0);
  font-size: 13px;
  background-color: rgb(255, 255, 255);
  text-align: justify;
  padding: 15px;
}

@media only screen and (max-width: 899px) {
  /* For mobile phones: */
  .container {
    width: 100vw;
  }

  .close {
    top: 4px;
    right: 4px;
  }
}
