.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.profile {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #ffffff;
}

.number {
  font-size: 18px;
  color: #696969;
}

.inputField {
  background-color: rgb(255, 255, 255);
  width: 70%;
  margin: 10px;
}

.btnContainer {
  display: flex;
  gap: 10px;
  margin: 20px 0;
}

.btn {
  position: relative;
  justify-content: center;
  margin: 0 auto;
  background-color: rgb(253, 199, 11);
  display: flex;
  border: none;
  padding: 10px;
  width: 200px;
  flex: 1;
  cursor: pointer;
}

.savebtn {
  composes: btn;
  background-color: rgb(21, 40, 89);
  color: #f2f2f2;
}

.btn:disabled {
  background-color: rgb(146, 146, 146);
  color: #f2f2f2;
  cursor: not-allowed;
}

@media only screen and (max-width: 899px) {
  /* For mobile phones: */
  .container {
    padding: 0;
  }

  .btn {
    padding: 6px;
    width: 130px;
  }

  .sidebar {
    display: none;
  }

  .inputField {
    margin: 10px 0;
  }
}
